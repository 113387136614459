import React, { Component, Fragment, useRef, useState, useEffect } from 'react'
import ReactGA from 'react-ga';
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'

import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import { init } from "ityped";

import Footer from './Footer';


const TRACKING_ID = "G-0FK93PHN54";
ReactGA.initialize(TRACKING_ID);

function Landing() {

useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}, []);


const onHandleSubmit= (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ge1vhvs', 'template_lpir1uw', e.target, '9QrCBh06BRgq6s25x')
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon:'success',
          title:'“Thank You for Subscribing” 👍'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon:'error',
          title:"Something went wrong 🙃",
          text:error.text,
        })
      });
      e.target.reset();
  };

      return (
  		<Fragment>
            <nav>
              <div className="nav__logo"><Link to="/">Thenavigo</Link></div>
              <ul className="nav__links">
                <li className="link"><Link to="/login" className="nav__btn">Get Started</Link></li>
              </ul>
            </nav>
            <section className="container">
              <div className="content__container">
                <h1>
                  The best <br />
                  <span className="heading__1">online Community Platform</span><br />
                  <span className="heading__2">in The World</span>
                </h1>
                <p>
                  Invaluable content for The leaders of today (and Tomorrow).
                </p>
                <form onSubmit={onHandleSubmit}>
                  <input type="text" name="user_email" placeholder="Join the Waitlist" required/>
                  <button type="submit">Join the Waitlist</button>
                </form>
              </div>
              <div className="image__container">
                <img src={require('../../images/pat.jpg')} alt="header" />
                <img src={require('../../images/teamwork.jpg')} alt="header" />
                <div className="image__content">
                  <ul>
                    <li>Find people with whom you share the same interests</li>
                    <li>Stay in touch so you can share your passions with each other</li>
                  </ul>
                </div>
              </div>
            </section>      

            
  		
  		</Fragment>

  				)
			}

export default Landing;